import './polyfills'
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './assets/base.scss';
import Main from './layout/AppMain/';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'

const { store, persistor } = configureStore();
const rootElement = document.getElementById('root');

const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <Component />
                </BrowserRouter>
            </PersistGate>
        </Provider>,
        rootElement
    );
};

renderApp(Main);

// if (module.hot) {
//     module.hot.accept('./layout/AppMain/', () => {
//         const NextApp = require('./layout/AppMain/').default;
//         renderApp(NextApp);
//     });
// }
serviceWorker.unregister();

import React from 'react'
import cx from 'classnames'
import { TiTickOutline, TiTimesOutline } from 'react-icons/ti'
import Loader from 'react-loaders'
import { useState, useEffect } from 'react'


const Messages = ({ pending, messages }) => {
    const [show, setShow] = useState(true)
    useEffect(() => {
        const timer = setTimeout(() => {
          setShow(false)
        }, 10000)
        return () => clearTimeout(timer);
    }, []);

    if(!show)
    {
        return null;
    }
    if(pending) {
        return (
            <>
                <div className="align-self-center pt-5 d-flex align-items-center flex-column">
                    <h4>Solo un attimo...</h4>
                    <Loader type="ball-pulse" />
                </div>
            </>
        )
    }
    else{
        return (
        messages &&
            <div className={cx("message my-3 shadow", {
                success: messages.status,
                error: !messages.status,
            })}
            >
                {messages.status ? <TiTickOutline className="icons" /> : <TiTimesOutline className="icons" />}
                <span className="text">{messages.message}</span>
            </div>
        )
    }


}

export default Messages;
import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    Container,
    Col,
    Row,
    Button,
    FormGroup,
    Form,
    Label,
    Input,
} from 'reactstrap'
import { Link, withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group';
import DatePicker from "react-datepicker";
import {
    PageTitle,
    ButtonLoading,
    ErrorCard
} from '../../../components/'
import Select from 'react-select'
import { 
    getDocumentModelsAction, 
    addDocumentAction, 
    getDocumentsAction, 
    cleanUpDocumentAction 
} from '../../../actions/documentAction'
import { getEmployeesAction } from '../../../actions/employeesAction'
import { parseForOptions, formatDate } from '../../../utils/select/transformIntoOption'
import { getCompaniesAction, getCompanyEmployeesAction } from '../../../actions/companyAction'
import { typeImages, errorImg, confirmImg } from '../../../assets/images/';

class DocumentAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showForm: true,
            id: null,
            address: null,
            start_date: new Date(),
            expiration_date: null,
            expiration: '',
            documentModelId: '',
            companyId: '',
            employeeId: '',
            select: {
                documentModelId: null,
                companyId: null,
                employeeId: null
            },
            duplicateError:false
        }

        this.initialState = this.state
    }

    toggle = () => this.setState(prevState => ({ modal: !prevState.modal }));

    validateForm = () => {
        console.log("validazione form")
        const { companyId, expiration_date, start_date, documentModelId } = this.state
        if(companyId && expiration_date && start_date && documentModelId) {
            return false
        }
        return true
    }

    componentDidMount() {
        this.props.getModels();
        this.props.getCompanies();
        this.props.getEmployees();
        if(this.props.location.state) {
            if(this.props.location.state.companyId) {
                const { companiesOption, location: { state: { companyId }} } = this.props;
                const selectedCompany = companiesOption.filter(company =>  company.value === companyId )
                this.setState(prevState => ({
                        ...prevState, 
                        companyId: companyId,
                        select: {
                            ...prevState.select,
                            companyId: selectedCompany
                        }
                    })
                )

                if(this.props.location.state.employeeId) {
                    const { employeesOption, location: { state: { employeeId }} } = this.props;
                    const selectedEmployee = employeesOption.filter(employee =>  employee.value === employeeId )
                    this.setState(prevState => ({
                            ...prevState, 
                            employeeId: employeeId,
                            select: {
                                ...prevState.select,
                                employeeId: selectedEmployee
                            }
                        })
                    )
                }
            }            
        }
    }

    get companyEmployees() {
        const { companyId } = this.state
        const allEmployees = Object.values(this.props.employees.byId)
        const company = this.props.companies.byId[companyId];
    
        if(!company) return []
        if (!company.employees) {
            return []
        }
        const filteredEmployee = allEmployees.filter((employee) => company.employees.includes(employee.id))
        console.log(companyId, allEmployees, company, company.employees, filteredEmployee, parseForOptions(filteredEmployee, 'id', 'first_name', 'last_name'))
        return parseForOptions(filteredEmployee, 'id', 'first_name', 'last_name')
    }

    handleChange =  evt  => {
       
        const {value, name} = evt.target
        
        this.setState( prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    handleSelect = (evt, metadata ) => {
        if(!evt) {
            return this.setState( prevState => ({
                ...prevState,
                [metadata.name]: null,
                select: {
                    ...prevState.select,
                    [metadata.name]: null
                }
            }))
    
        }
        console.log(evt)
        const valueForSelect = evt;
        const value = evt.value;
        const name = metadata.name;
        this.setState( prevState => ({
            ...prevState,
            [name]: value,
            select: {
                ...prevState.select,
                [name]: valueForSelect
            }
        }))

        if(name === 'documentModelId') {
            const { models } = this.props;
            const { start_date } = this.state;
            const newEndDate = new Date(start_date.getTime())
            const model = models.byId[Number(value)]

            if(model) {
                const endDate = new Date(newEndDate.setMonth(newEndDate.getMonth() + model.expiration))
                this.handleChangeEndDate(endDate)
            }
        }

        if(name === 'companyId') {
            this.setState( prevState => ({
                ...prevState,
                employeeId: '',
                select: {
                    ...prevState.select,
                    employeeId: null
                }
            }), () => console.log(this.state))
            this.props.getEmployees(value)
        }
    }

    handleChangeStartDate = date => {
        console.log(date)
        this.setState( prevState => ({
            ...prevState,
            start_date: date
        }), () => console.log(this.state))

        const { models } = this.props;
        const { documentModelId } = this.state;
        const newEndDate = new Date(date.getTime())
        const model = models.byId[Number(documentModelId)]

        if(model) {
            const endDate = new Date(newEndDate.setMonth(newEndDate.getMonth() + model.expiration))
            this.handleChangeEndDate(endDate)
        }
    }

    handleChangeEndDate = date => {
        console.log(date)

        this.setState( prevState => ({
            ...prevState,
            expiration_date: date
        }), () => console.log(this.state))
    }
    
    submitForm = () => {

        const expirationDate = 
            this.state.expiration_date ? 
            formatDate(this.state.expiration_date) : null

        const document = {
            address: this.state.address,
            company_id : this.state.companyId,  
            employee_id: this.state.employeeId,
            documentmodel_id: this.state.documentModelId,  
            expiration_date: expirationDate,
            start_date: formatDate(this.state.start_date)
        }

        const { companies, documents } = this.props
        const company = companies.byId[document.company_id]

        // company.documents.map(id => {
        //     const checkDocumentAgainst = documents.byId[id]
        //     // Questa è una follia andrebbe fatto in backend non qui maremma troia
        //     if(document.employee_id === checkDocumentAgainst.employee_id && 
        //        document.documentmodel_id === checkDocumentAgainst.documentmodel_id &&
        //        document.expiration_date <= checkDocumentAgainst.expirationDate) {
        //            this.setState({duplicateError: true})
        //        }
        // })
        this.props.submitForm(document)
    }

    resetState = () => {
        this.props.cleanUpDocument()
        this.setState(prevState => (
            {   ...this.initialState, 
                companyId:prevState.companyId, 
                select:{
                    ...this.initialState.select,
                    companyId: prevState.select.companyId
                }
            }
        ))
    }

    componentWillUnmount() {
        this.props.cleanUpDocument()
    }

    render() {

        const { employees, companies, modelsOption, companiesOption, isPending, isCreated, error, errorUnserialized } = this.props;
        const { select, companyId, showForm } = this.state;
        console.log(employees);

        if(isCreated.status && companyId) {
            return (
                <Container fluid >
                    <Row>
                        <Col md="12">
                            <PageTitle
                                heading="Aggiungi un nuovo documento"
                                subheading="Qui puoi creare nuovi modelli di documenti, questo ti permetterà di inserire velocemente una nuova scadenza
                                selezionando un modello già esistente."
                                icon={false}
                            />
                        </Col>
                    </Row>
                    <Row className="d-flex flex-column justify-content-center align-items-center">
                        <Col md="8">
                            <ErrorCard img={confirmImg} customText={`Abbiamo aggiunto il documento all\'azienda ${companies.byId[companyId].business_name}!`}>
                                <Button className="mt-4 btn-shadow" onClick={this.resetState} color="primary">
                                    Continua ad inserire documenti
                                </Button>
                            </ErrorCard>
                        </Col>
                    </Row>                       
                </Container>
            ) 

        }
        return (

            <Container fluid >
                <Row>
                    <Col md="12">
                        <PageTitle
                            heading="Aggiungi un nuovo documento"
                            subheading="Qui puoi creare nuovi modelli di documenti, questo ti permetterà di inserire velocemente una nuova scadenza
                            selezionando un modello già esistente."
                            icon={false}
                        />
                        <Row className="d-flex flex-column align-items-center">
                            <Col md="10">
                                <CSSTransition
                                in={error && !isCreated.status}
                                timeout={300}
                                classNames="alert"
                                unmountOnExit
                                onEnter={() => this.setState({showForm: false})}
                                onExited={() => this.setState({showForm: true})}
                                >
                                    { error && 
                                        <ErrorCard img={errorImg} customText={errorUnserialized}></ErrorCard>
                                    }
                                </CSSTransition>
                                
                                <Form className="form-document mt-5">
                                    <Row>
                                        <Col md={6}>
                                            <h4>Modello del documento</h4>
                                            <p className="subheading">Creeremo un nuovo documento a partire dal modello selezionato</p>
                                        </Col>
                                        <Col md={6}>
                                            <h4>Indirizzo associato</h4>
                                            <p className="subheading">
                                                Qualora un'azienda avesse più sedi/unità puoi inserire qui un indirizzo per associarlo alla sede scelta
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="mb-5">
                                        <Col md={6}>
                                            <FormGroup>
                                                <Select 
                                                    value={select.documentModelId} 
                                                    onChange={this.handleSelect} 
                                                    name="documentModelId" 
                                                    className="bigger" 
                                                    options={modelsOption}>
                                                </Select>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input 
                                                    name="address"
                                                    value={this.state.address}
                                                    onChange={this.handleChange}>
                                                    </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <h4>Azienda</h4>
                                            <p className="subheading">Scegli l'azienda a cui attribuire il nuovo documento</p>
                                        </Col>
                                        <Col md={6}>
                                            <h4>Dipendente <font color="#999">(opzionale)</font></h4>
                                            <p className="subheading">Scegli il dipendente a cui attribuire il nuovo documento</p>
                                        </Col>
                                    </Row>
                                    <Row className="mb-5">
                                        <Col md={6}>
                                            <FormGroup>
                                                <Select 
                                                    value={select.companyId} 
                                                    onChange={this.handleSelect} 
                                                    name="companyId" 
                                                    className="bigger" 
                                                    options={companiesOption}>
                                                </Select>
                                                {/* <Input type="select" onChange={this.getEmployeesForCompany} name="companyId" className="bigger">
                                                    { companies.map((company, index) => (
                                                        <option key={index} value={company.id}>{company.name}</option>
                                                    ))}
                                                </Input> */}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                        {companyId ? (
                                            <FormGroup>
                                                {/* <Input type="select" onChange={this.handleChange} name="employeeId" className="bigger">
                                                    { employees.employees.map((employee, index) => (
                                                        <option key={index} value={employee.id}>{employee.first_name} {employee.last_name}</option>
                                                    ))}
                                                </Input> */}
                                                <Select 
                                                    value={select.employeeId} 
                                                    onChange={this.handleSelect} 
                                                    isClearable={true}
                                                    name="employeeId" 
                                                    className="bigger" 
                                                    options={this.companyEmployees}>
                                                </Select>
                                            </FormGroup>
                                        ) : ( <h5 className="text-muted font-italic pt-2">Seleziona un'azienda per vederne i dipendenti</h5>)}
                                        </Col>
                                    </Row>

                                    <Row className="mb-5">
                                        <Col md={7}>
                                            <Row className="">
                                                <Col md={12} className="mb-3">
                                                    <h4>Validità del documento</h4>
                                                    <p className="subheading">
                                                        La validità del documento è stabilita a partire dalla validità del modello che stai usando, 
                                                        puoi comunque modificare sia l'inizio che la fine a tuo piacimento.
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label>Data di INIZIO validità</Label>
                                                        <DatePicker
                                                            dateFormat='yyyy-MM-dd' 
                                                            selected={this.state.start_date}
                                                            onChange={this.handleChangeStartDate}
                                                            className="form-control bigger"
                                                        />                                            
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label>Data di FINE validità</Label>
                                                        <DatePicker
                                                            dateFormat='yyyy-MM-dd' 
                                                            selected={this.state.expiration_date}
                                                            onChange={this.handleChangeEndDate}
                                                            className="form-control bigger"
                                                        />                                               
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row className="justify-content-center">
                                    <Col md={12}>
                                        <Button className="btn-shadow mr-4 bigger" onClick={this.resetState} color="danger">Pulisci i campi</Button>
                                        <ButtonLoading onClick={this.submitForm}  className="btn-shadow bigger" loading={isPending} color="primary">Salva il modello</ButtonLoading>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>  
                    </Col>
                </Row>
            </Container >
        )
    }
}

const mapDispatchToProps = dispatch => ({
    getModels: () => dispatch(getDocumentModelsAction()),
    getCompanies : () => dispatch(getCompaniesAction()),
    getEmployees : (companyId) => dispatch(getCompanyEmployeesAction(companyId)),
    getAllEmployees: () => dispatch(getEmployeesAction()),
    submitForm: (document) => dispatch(addDocumentAction(document)),
    cleanUpDocument: () => dispatch(cleanUpDocumentAction())
})

const mapStateToProps = state => {

    const isCreated = state.documents.documents.created
    const isPending = state.documents.documents.pending
    const error = state.documents.documents.error
    let errorUnserialized = false;
    if(error) {
        errorUnserialized = JSON.parse(error.serialized)
    }
    const models = state.documents.documentModels
    const modelsOption = parseForOptions(Object.values(models.byId), 'id', 'name');
    const companies = state.companies
    const companiesOption = parseForOptions(Object.values(companies.byId), 'id', 'business_name');
    const employees = state.employees    
    const employeesOption = parseForOptions(Object.values(employees.byId), 'id', 'first_name', 'last_name');
    
    return { 
        isPending, 
        isCreated, 
        error, 
        errorUnserialized, 
        models, 
        companies, 
        employees, 
        modelsOption, 
        companiesOption ,
         employeesOption 
    };
    

}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentAdd);
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Col, Button } from 'reactstrap'
import { FaPlus, FaFileDownload, FaFile, FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { PageTitle, DataTable, ViewTooltip, FormInfo } from '../../../components/';
import { getCompaniesAction } from '../../../actions/companyAction'
import { getEmployeesAction } from '../../../actions/employeesAction'
import { getDocumentsAction, getDocumentModelsAction, deleteDocumentAction, editDocumentAction } from '../../../actions/documentAction'
import { schema } from '../../../utils/forms/company'
import { alphanumInsensitiveSort } from '../../../utils/'
import cx from 'classnames'
import { formatDate } from '../../../utils/select/transformIntoOption'
import { isEqual } from 'lodash'
import { CSVLink, CSVDownload } from "react-csv";

const RapidAction = ({data, date}) => (
    <Col md="3" className="pr-5 d-flex flex-column justify-content-start align-items-end">
        <h4 className="mt-2 mb-3 mr-2 text-black">
            Azioni rapide
                                        </h4>
        <Link to='/document/add'>
            <Button className="new-expiration-button py-1 pl-1 pr-3 shadow mb-2 d-flex text-left align-items-center">
                <div className="mr-3">
                    <FaPlus className="hoverable-icon static brand p-2"></FaPlus>
                </div>
                <div className="text-black">
                    Inserisci nuova scadenza
                </div>
            </Button>
        </Link>
        <Button disabled={false} className="new-expiration-button py-1 pl-1 pr-3 shadow mb-2 d-flex text-left align-items-center">
            <div className="mr-3">
                <FaFileDownload className="hoverable-icon static brand p-2"></FaFileDownload>
            </div>
            <CSVLink data={data} filename={"docuements"+date}>Esporta tabella in .csv</CSVLink>
        </Button>
    </Col>
)

const ActiveFilter = ({ filter, onClick }) => {
    if (filter) {
        return <Button color="warning" onClick={() => onClick()}>Scade entro {filter} giorni <FaTimes></FaTimes></Button>
    }
    if (filter === 0) {
        return <Button color="danger" onClick={() => onClick()}>Solo già scadute <FaTimes></FaTimes></Button>
    }
    return <h6 className="d-inline">Nessun filtro attivo</h6>
}

const Filters = ({ handleClick, active, days }) => (
    <div className="datatable-filters d-flex flex-row">
        {days.map(day => (
            <Button
                className={cx("mr-2 btn-pill", {
                    "d-none": active === day
                })}
                color="focus"
                onClick={() => handleClick(day)}>
                {day === 0 ? "Scadute" : `Scade entro ${day} giorni`}
            </Button>
        ))}
    </div>

)

class DocumentList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            documents: [],
            filter: false,
            loadingRowId: null,
            employees: [],
        }
    }

    renderCell = (field, value, row) => {
        switch (field) {
            case "id":
                return (
                    <td className="text-center text-muted p-3" name={field}>
                        {value}
                    </td>
                );

            case "Azienda":
                const company = this.props.companies.byId[row.original.company_id]
                const href = company ? `/company/edit/${company.id}` : "#"
                const companyName = company ? company.business_name : "Errore nel recuperare l'azienda"
                return (
                    <td className="text-center p-3" name={field}>
                        <a href={href}>{value}</a>
                    </td >
                );



            case "Assegnato a":
                return (
                    <td className={cx("text-center p-3", {
                        "opacity-5": !value
                    })}>
                        {value ? value : "Nessun dipendente"}
                    </td>
                );

            case "expiration_date":
                const today = new Date
                const isExpired = new Date(value) <= today
                return (
                    <td className="text-center p-3 font-weight-bold">
                        <div className={cx("exp-date", {
                            "expired": isExpired
                        })}>
                            {value}
                        </div>
                    </td>
                );

            default:
                return (
                    <td className="text-center p-3" name={field}>
                        {value}
                    </td>
                );
        }
    };

    getExpirationByTime = timespan => {
        const allDocuments = Object.values(this.props.documents)
        if (!allDocuments.length > 0) {
            return []
        }
        const today = new Date()
        const tmpDate = new Date()
        const timespanDate = new Date(tmpDate.setDate(tmpDate.getDate() + timespan))

        if (timespan === 0) {
            return allDocuments.filter(document => {
                const expirationDate = new Date(document.expiration_date)
                return today > expirationDate
            })
        }

        if (!timespan) {
            return allDocuments
        }


        return allDocuments.filter(document => {
            const expirationDate = new Date(document.expiration_date)
            return today < expirationDate && expirationDate < timespanDate
        })
    }

    async componentDidMount() {
        await this.props.getDocumentModels()
        await this.props.getCompanies()
        await this.props.getEmployees()
        await this.props.getDocuments()

        if (this.props.location.state &&
            (this.props.location.state.filter || this.props.location.state.filter === 0)) {
            const { filter } = this.props.location.state
            return this.filterTable(filter)
        }

        this.setState({
            documents: Object.values(this.props.documents),
        })
    }

    filterTable = filter => {
        const filteredDocuments = this.getExpirationByTime(filter)
        this.setState({
            documents: filteredDocuments,
            filter: filter
        })
    }

    handleFilterReset = () => {
        this.setState({
            documents: Object.values(this.props.documents),
            filter: false
        })
    }

    handleDelete = async id => {
        this.setState({ loadingRowId: id })
        await this.props.deleteDocument(id)
        if (!this.props.documents[id]) {
            const updatedDocuments = this.state.documents.filter(document => document.id !== id)
            this.setState({ documents: updatedDocuments, loadingRowId: null })
        }
    }

    handleRenew = async (data) => {
        console.log(data)
        if (!this.props.documents[data.documentId]) {
            return
        }

        let document = JSON.parse(JSON.stringify(this.props.documents[data.documentId]))
        document.start_date = formatDate(data.start_date)
        document.expiration_date = formatDate(data.expiration_date)
        const result = await this.props.editDocument(document)
        console.log(document, result)
    }

    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(prevProps.documents, this.props.documents)) {
            this.setState({
                documents: Object.values(this.props.documents),
            })
        }
    }

    getEmployeeForDocument = row => {
        const employees = this.props.employees.byId
        return employees[row.employee_id] &&
            `${employees[row.employee_id].first_name} ${employees[row.employee_id].last_name}`
    }

    activateFilter = filter => {
        this.filterTable(filter)
    }

    get allDocuments(){
        
        const allDocuments = Object.values(this.props.documents);
        const documentModels = Object.values(this.props.documentModels);
        const companies = this.props.companies.byId;
        const employees = this.props.employees.byId;
        let filterdocuments = allDocuments.sort((a, b) => new Date(a.expiration_date) - new Date(b.expiration_date));
                //.filter((document) => company.documents.includes(document.id))  
        const data = filterdocuments.map(document => {
            const documentModel = documentModels.find(({id})=>id==document.documentmodel_id)           
            const employee = employees[document.employee_id] 
            const company = companies[document.company_id]
            return {
                ...document,
                documentmodel_id: documentModel.name,
                company_id: company.business_name,
                employee_id: employee && `${employee.first_name} ${employee.last_name}`
            }
        })
        return data;
    }

    render() {

        const { documents, documentModels, companies, employees } = this.props;
        // console.log(documentModels);
        // console.log(companies);
        // console.log(employees);
        const today = new Date().toLocaleDateString().replace(/\//g, '-');
        return (
            <Fragment>

                <PageTitle
                    heading="Documenti"
                    subheading="La lista di tutti i documenti inseriti nel gestionale."
                    icon={false}
                    bread=""
                >
                    <RapidAction data={this.allDocuments} date={today} />
                </PageTitle>

                {documents && documentModels &&
                    <DataTable
                        handleDelete={this.handleDelete}
                        handleRenew={this.handleRenew}
                        schema={schema}
                        hasViewTooltip={false}
                        title="Cerca:"
                        loadingRowId={this.state.loadingRowId}
                        availableFilter={
                            <Filters
                                handleClick={this.activateFilter}
                                active={this.state.filter}
                                days={[0, 7, 30, 60]} />
                        }
                        columns={[
                            {
                                Header: 'ID',
                                accessor: 'id'
                            },
                            {
                                Header: 'Tipo',
                                accessor: row => documentModels[row.documentmodel_id] && documentModels[row.documentmodel_id].name,
                                sortType: alphanumInsensitiveSort
                            },
                            {
                                Header: 'Azienda',
                                accessor: row => {
                                    const company = this.props.companies.byId[row.company_id]
                                    const companyName = company ? company.business_name : "Errore nel recuperare l'azienda"
                                    return companyName
                                },
                                sortType: alphanumInsensitiveSort
                            },
                            {
                                Header: 'Assegnato a',
                                accessor: row => this.getEmployeeForDocument(row)
                            },
                            {
                                Header: 'Scadenza',
                                accessor: 'expiration_date'
                            },
                        ]} data={this.state.documents} model={"document"} renderCell={this.renderCell}
                    >
                        <ActiveFilter
                            filter={this.state.filter}
                            onClick={this.handleFilterReset}>
                        </ActiveFilter>
                    </DataTable>
                }

            </Fragment>

        )
    }
}

const mapDispatchToProps = dispatch => ({
    getCompanies: () => dispatch(getCompaniesAction()),
    getEmployees: () => dispatch(getEmployeesAction()),
    getDocuments: () => dispatch(getDocumentsAction()),
    getDocumentModels: () => dispatch(getDocumentModelsAction()),
    deleteDocument: id => dispatch(deleteDocumentAction(id)),
    editDocument: document => dispatch(editDocumentAction(document))
})

const mapStateToProps = state => {
    const documents = state.documents.documents.byId
    const documentModels = state.documents.documentModels.byId
    const companies = state.companies
    const employees = state.employees

    return { documents, documentModels, companies, employees }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentList)
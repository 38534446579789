import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FaEye, FaPlus } from 'react-icons/fa'
import { FiTrash2 } from 'react-icons/fi'
import {
    PageTitle,
    ButtonLoading,
    Messages,
    ActionTooltip
} from '../../../components/'
import Select from 'react-select'
import { connect } from 'react-redux'
import {
    Button, CardHeader,
    Row, Col, Input,
    Card, CardBody,
    CardFooter, Container,
    FormGroup, Label
} from 'reactstrap';
import DatePicker from "react-datepicker";
import { parseForOptions, formatDate } from '../../../utils/select/transformIntoOption'
import { getCompaniesAction, getCompanyEmployeesAction } from '../../../actions/companyAction'
import { getEmployeesAction } from '../../../actions/employeesAction'
import {
    getDocumentsAction,
    getDocumentModelsAction,
    editDocumentAction,
    deleteDocumentAction
} from '../../../actions/documentAction'


const RapidAction = ({ company, document, handleDelete }) => (
    <Col md="4" className="pr-5 d-flex flex-column justify-content-start align-items-end">
        <h4 className="mt-2 mb-3 mr-2 text-light">
            Azioni rapide
                                        </h4>
        <Button disabled={true} className="new-expiration-button py-1 pl-1 pr-3 shadow mb-2 d-flex text-left align-items-center">
            <div className="mr-3">
                <FaPlus className="hoverable-icon static brand p-2"></FaPlus>
            </div>
            <div className="text-black">
                Rinnova scadenza
                </div>
        </Button>
        <Link to={`/company/edit/${document.company_id}/`}>
            <Button className="new-expiration-button py-1 pl-1 pr-3 shadow mb-4 d-flex text-left align-items-center">
                <div className="mr-3">
                    <FaEye className="hoverable-icon static brand p-2"></FaEye>
                </div>
                <div className="text-black">
                    Vai all'azienda
                </div>
            </Button>
        </Link>
        {handleDelete &&
            <ActionTooltip
                id={`item-delete`}
                handleDelete={() => handleDelete(document.id)}
                item={{ placement: "bottom" }}
                text={"Sei sicuro di voler eliminare questo oggetto?"}
                color="white"
            />
        }
        <Button id={`Tooltip-item-delete`} className="new-expiration-button py-1 pl-1 pr-3 shadow mb-3 d-flex text-left align-items-center">
            <div className="mr-3">
                <FiTrash2 className="hoverable-icon static danger p-2"></FiTrash2>
            </div>
            <div className="text-danger">
                Cancella il documento
            </div>
        </Button>
    </Col >
)

class DocumentEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            showForm: true,
            start_date: new Date(),
            expiration_date: new Date(),
            address: '',
            documentmodel_id: '',
            company_id: '',
            employee_id: '',
            select: {
                documentmodel_id: null,
                company_id: null,
                employee_id: null
            }
        }

    }

    get companyEmployees() {
        const { company_id } = this.state
        if (!company_id) return []
        const allEmployees = Object.values(this.props.employees.byId)
        const company = this.props.companies.byId[company_id];
        if (!company) return [];
        if (!company.employees) {
            return []
        }
        const filteredEmployee = allEmployees.filter((employee) => company.employees.includes(employee.id))
        return parseForOptions(filteredEmployee, 'id', 'first_name', 'last_name')
    }

    handleChange = evt => {

        const { value, name } = evt.target

        this.setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    handleChangeStartDate = date => {
        console.log(date)
        this.setState(prevState => ({
            ...prevState,
            start_date: date
        }), () => console.log(this.state))

        const { documentModels } = this.props;
        const newEndDate = new Date(date.getTime())
        const model = documentModels.byId[this.state.documentmodel_id]

        if (model) {
            const endDate = new Date(newEndDate.setMonth(newEndDate.getMonth() + model.expiration))
            this.handleChangeEndDate(endDate)
        }
    }

    handleChangeEndDate = date => {
        console.log(date)

        this.setState(prevState => ({
            ...prevState,
            expiration_date: date
        }), () => console.log(this.state))
    }

    handleSelect = (evt, metadata) => {
        const valueForSelect = evt;
        const value = evt.value;
        const name = metadata.name;
        console.log(valueForSelect, value, name)
        this.setState(prevState => ({
            ...prevState,
            [name]: value,
            select: {
                ...prevState.select,
                [name]: valueForSelect
            }
        }), () => console.log(this.state))

        // if (name === 'documentmodel_id') {
        //     const { models } = this.props;
        //     const { start_date } = this.state;
        //     const newEndDate = new Date(start_date.getTime())
        //     const model = models.byId[Number(value)]

        //     if (model) {
        //         const endDate = new Date(newEndDate.setMonth(newEndDate.getMonth() + model.expiration))
        //         this.handleChangeEndDate(endDate)
        //     }
        // }

        if (name === 'company_id') {
            this.setState(prevState => ({
                ...prevState,
                employee_id: '',
                select: {
                    ...prevState.select,
                    employee_id: null
                }
            }), () => console.log(this.state))
            this.props.getEmployees(value)
        }
    }

    handleDelete = async id => {
        await this.props.deleteDocument(id)
        this.props.history.push('/document/')
    }

    async componentDidMount() {

        await this.props.getDocumentModels();
        await this.props.getCompanies();


        const {
            companies,
            documents, documentModels,
            modelsOption, companiesOption,
            match: { params: { id } }
        } = this.props;


        if (!id) {
            return false;
        }

        if (!this.props.documents.byId[id]) {

            await this.props.getDocuments()

            if (!this.props.documents.byId[id])
                throw new Error("Something wrong happened?")

        }
        const document = this.props.documents.byId[id]
        const { company_id, employee_id, documentmodel_id } = document

        const selectCompany = {
            value: company_id,
            label: companies.byId[company_id].business_name
        }

        const selectDocumentModels = {
            value: documentmodel_id,
            label: documentModels.byId[documentmodel_id].name
        }

        let selectEmployee = {}
        if (document.employee_id) {
            await this.props.getEmployees(company_id)
            const { employees } = this.props
            const employeeName = `${employees.byId[employee_id].first_name} ${employees.byId[employee_id].last_name}`
            selectEmployee.value = employee_id
            selectEmployee.label = employeeName
        }

        this.setState(prevState => ({
            ...document,
            showForm: employee_id ? true : false,
            select: {
                company_id: selectCompany,
                employee_id: selectEmployee,
                documentmodel_id: selectDocumentModels
            }
        }), () => console.log("document/edit updating state", this.state))
    }

    submitForm = () => {
        const expirationDate =
            this.state.expiration_date ?
                formatDate(this.state.expiration_date) : null

        const document = {
            id: this.state.id,
            address: this.state.address,
            company_id: this.state.company_id,
            employee_id: this.state.employee_id,
            documentmodel_id: this.state.documentmodel_id,
            expiration_date: expirationDate,
            start_date: formatDate(this.state.start_date)
        }
        this.props.submitForm(document).then(() => {
            this.props.history.push('/company/edit/'+this.state.company_id)
          }).catch((error) => {
            console.log(error)
          })
    }

    render() {

        const {
            employees, companies, isPending,
            documents, documentModels,
            modelsOption, companiesOption,
            match: { params: { id } }, edited
        } = this.props;

        const {
            select, employee_id, showForm,
            start_date, expiration_date
        } = this.state;

        const document = documents.byId[id];
        const startDateObj = new Date(start_date)
        const expirationDateObj = new Date(expiration_date)
        const documentModel = document && documentModels.byId[document.documentmodel_id]

        return (
            <Container fluid>
                {documentModels && document && <PageTitle
                    heading={`${documentModel.name}`}
                    subheading={`Inserito il ${document.created_at} in ${companies.byId[document.company_id].business_name}`}
                    bread={companies.byId[document.company_id].business_name}
                    icon={false}
                    color={true}
                    expiration={documentModel.expiration}
                    expirationDate={formatDate(expirationDateObj)}

                >
                    <RapidAction
                        handleDelete={this.handleDelete}
                        document={document}
                    ></RapidAction>
                </PageTitle>}
                <Row>
                    <Col md="7">
                        <Card className="main-card mb-3">
                            <CardHeader className="card-head">
                                <h5>Dati anagrafici</h5>
                            </CardHeader>
                            <CardBody className="pt-4">
                                <Messages
                                    pending={isPending}
                                    messages={
                                        edited.status && {
                                            status: edited.status,
                                            message: edited.message
                                        }}
                                />

                                <FormGroup>
                                    <h6>Assegnato all'indirizzo</h6>
                                    <Input
                                        name="address"
                                        value={this.state.address}
                                        onChange={this.handleChange}
                                    ></Input>
                                </FormGroup>
                                <FormGroup>
                                    <h6>Tipo di documento</h6>
                                    <Select
                                        value={select.documentmodel_id}
                                        isLoading={!select.documentmodel_id}
                                        onChange={this.handleSelect}
                                        name="documentmodel_id"
                                        className="bigger"
                                        options={modelsOption}>
                                    </Select>
                                </FormGroup>
                                <FormGroup>
                                    <h6>Azienda</h6>
                                    <Select
                                        value={select.company_id}
                                        onChange={this.handleSelect}
                                        name="company_id"
                                        className="bigger"
                                        isLoading={!select.company_id}
                                        options={companiesOption}>
                                    </Select>
                                </FormGroup>
                                <FormGroup>
                                    {
                                        showForm ? (
                                            <>
                                                <h6>Dipendente</h6>
                                                <Select
                                                    value={select.employee_id}
                                                    isLoading={!select.employee_id}
                                                    onChange={this.handleSelect}
                                                    name="employee_id"
                                                    className="bigger"
                                                    options={this.companyEmployees}>
                                                </Select>
                                            </>
                                        ) : (
                                                <h5 className="text-muted font-italic">
                                                    Questo documento non è assegnato ad un dipendente
                                                    <br></br>
                                                    <a href="#" onClick={() => this.setState({ showForm: true })}>Assegnalo ora</a>
                                                </h5>
                                            )
                                    }
                                </FormGroup>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Data di INIZIO validità</Label>
                                            <DatePicker
                                                dateFormat='yyyy-MM-dd'
                                                selected={startDateObj}
                                                onChange={this.handleChangeStartDate}
                                                className="form-control bigger"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label>Data di FINE validità</Label>
                                            <DatePicker
                                                dateFormat='yyyy-MM-dd'
                                                selected={expirationDateObj}
                                                onChange={this.handleChangeEndDate}
                                                className="form-control bigger"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className="justify-content-end">
                                <Row className="justify-content-center">
                                    <Col md={12}>
                                        <Button className="btn-shadow mr-4 bigger" onClick={this.resetState} color="danger">Pulisci i campi</Button>
                                        <ButtonLoading onClick={this.submitForm} className="btn-shadow bigger" loading={isPending} color="primary">Salva il modello</ButtonLoading>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container >
        )
    }
}


const mapDispatchToProps = dispatch => ({
    getDocumentModels: () => dispatch(getDocumentModelsAction()),
    getDocuments: (documentId) => dispatch(getDocumentsAction(documentId)),
    getCompanies: () => dispatch(getCompaniesAction()),
    getEmployees: (companyId) => dispatch(getCompanyEmployeesAction(companyId)),
    submitForm: (document) => dispatch(editDocumentAction(document)),
    deleteDocument: documentId => dispatch(deleteDocumentAction(documentId))
})

const mapStateToProps = state => {

    const isPending = state.documents.documents.pending
    const error = state.documents.documents.error
    let errorUnserialized = false;
    if (error) {
        errorUnserialized = JSON.parse(error.serialized)
    }
    const documentModels = state.documents.documentModels
    const modelsOption = parseForOptions(Object.values(documentModels.byId), 'id', 'name');

    const companies = state.companies
    const companiesOption = parseForOptions(Object.values(companies.byId), 'id', 'business_name');
    const employees = state.employees
    const employeesOption = parseForOptions(Object.values(employees.byId), 'id', 'first_name', 'last_name');
    const documents = state.documents.documents
    const edited = state.documents.documents.edited

    return {
        isPending, employees,
        error, errorUnserialized,
        documentModels, companies,
        modelsOption, companiesOption,
        employeesOption, documents,
        edited
    };
    // const { user } = state.User;
    // const isPending = state.User.pending;
    // const error = state.User.error;
    // const isAuthenticated = state.User.user && Object.entries(state.User.user).length !== 0;

    // return {
    //     isAuthenticated,
    //     user,
    //     isPending,
    //     error
    // }

}


export default connect(mapStateToProps, mapDispatchToProps)(DocumentEdit)

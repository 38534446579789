import axios from "axios";

let baseUrl = "http://localhost:8000";

if (process.env.NODE_ENV === "production") {
  baseUrl = "https://api-anon.digitra.me";
}

export default axios.create({
  baseURL: baseUrl,
  timeout: 1000,
  headers: { "X-Man-Token": "foobar" },
});
